import React, { useEffect, useRef, useState } from "react";
import ContentSection from "../../components/ContentSection/ContentSection";
import styles from "./landingPage.module.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utilities/scrollToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let [referenceElement, setreferenceElement] = useState(0);
  let [referenceElement2, setreferenceElement2] = useState(0);
  const videoRef = useRef(null);
  const navigation = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const video = videoRef.current;
    let x = document.querySelector("#navbar");
    let y = document.querySelector("#referencetoani");
    setreferenceElement(x.clientHeight);
    setreferenceElement2(y.clientHeight);
    video.play();
  }, []);
  const customStyles = {
    width: isMobile ? "88%" : "70%",
    marginLeft: "auto",
    textAlign: "start",
    fontSize: "2.7rem",
    lineHeight: "3.1rem",
    color: "rgb(0, 13, 16)",
    marginBottom: "1.5rem",
    paddingRight: isMobile ? "10px" : "40px",
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);
  const navgigateToNext = (path) => {
    navigation(path);
  };

  const section1_sub1_content = [
    {
      header: "EngageWell",
      // info: "Group Activities for boosting employees’s engagement.",
      info: "Group Activities for boosting employees’s happiness and engagement.",
    },
    {
      header: "OneWell",
      // info: "Fostering Individual Well-Being by providing <span style='font-weight:700;color:rgb(0, 13, 16);'>one-on-one well-being</span> sessions and activities.",
      info: "Individual well-being sessions in one-on-one or intimate size format.",
    },
    {
      header: "Wellbeing Connect",
      // info: "Training sessions for  <span style='font-weight:700;color:rgb(0, 13, 16);'>managers</span> focused on developing empathetic skills to enhance their understanding of employee well-being, happiness, and engagement.",
      info: "For leadership catering to their well-being, engagement and happiness at the company.",
    },
  ];
  const content =
    "Businesses struggle to keep their employees happy and engaged, possibly ignoring their well-being";
  return (
    <div>
      <section className={`${styles.navBtnsec} fadeIn `} id="referencetoani">
        <div style={{ width: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "0rem",
              zIndex: "-70",
              left: "0px",
              height: `${referenceElement + referenceElement2 + 38}px`,
              width: "100%",
            }}
          >
            <video
              ref={videoRef}
              width="100%"
              style={{ objectFit: "cover", height: "100%" }}
              autoPlay
              muted
              loop
            >
              <source
                src="/assets/content_image/landingPageVideo.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={styles.navBtns}>
          <Button
            onClick={() => {
              navgigateToNext("/products");
            }}
            sx={{ backgroundColor: "rgb(0, 13, 16)", whiteSpace: "nowrap" }}
            className="customBtn "
          >
            Product Modules
          </Button>
        </div>
      </section>
      <section>
        <ContentSection
          content={content}
          customStyles={customStyles}
          page="landingPage"
        ></ContentSection>
        <div
          style={{
            width: isMobile ? "80%" : "70%",
            marginLeft: "auto",
            paddingRight: "40px",
          }}
        >
          <div className={styles.section1_sub1}>
            {section1_sub1_content.map((data) => {
              return (
                <div
                  key={data.header}
                  className={`${styles.section1_sub1_item}  `}
                >
                  <h3>{data.header}</h3>
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.info,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
